import React from "react"
import { Link, graphql } from "gatsby"
import { Helmet } from "react-helmet"
import styled from 'styled-components'

import { FlexRow } from "../components/styles"
import Layout from "../components/layout"
import SEO from "../components/seo"

const Header = styled.div`
  display: flex;
`

const IndexPage = ({}) => {


  return (
    <Layout>
        Welcome!
  </Layout>
  )
}



export default IndexPage

